const StreamCard = ({cardPosition, cardVisibility, cardData}) => {
    const defaultDisabledClass = 'bg-purple-500'
    const organisation = (cardData.stream && cardData.stream.organisation && cardData.organisations[cardData.stream.organisation]) || {
        disabled_class: defaultDisabledClass, fullname: ''
    }
    return (<div className={`absolute bottom-0 mb-20 drop-shadow-md rounded-md
                            bg-gradient-to-br from-purple-900 via-purple-900 to-transparent ${organisation.disabled_class || defaultDisabledClass}
                            w-80 h-40 flex flex-col p-1 gap-1 justify-between ${cardVisibility ? '' : 'hidden'}`}
                 style={{left: cardPosition}}>
        {cardData && cardData.stream && (
            <div className="flex gap-1">
                <img className="rounded-md w-16 self-start"
                     src={cardData.stream.profile_image_url || ''}
                     alt={cardData.stream.display_name || ''}/>
                <div className="flex flex-col">
                    <div className="text-white text-lg">{cardData.stream.display_name || ''}</div>
                    <div className="text-slate-200 text-sm leading-tight">{cardData.stream.title || ''}</div>
                </div>
            </div>
        )}
        {cardData && cardData.stream && (
            <div className="flex justify-end gap-1">
                <div className="flex flex-col text-right">
                    <div className="text-white text-lg">{cardData.stream.name || ''}</div>
                    {cardData.organisations && cardData.stream.organisation && cardData.organisations[cardData.stream.organisation] && (
                        <div
                            className="text-slate-200 text-sm leading-tight">
                            {cardData.organisations[cardData.stream.organisation].fullname}
                        </div>)}
                </div>
            </div>
        )}
    </div>)
}

export default StreamCard
