const LayoutMenu = ({lives, layouts, layout, setLayout}) => {
    const selectedLayouts = layouts[lives.length] || []

    return (<div className="flex pl-1.5 pr-1">
        {selectedLayouts && selectedLayouts.length > 1 && selectedLayouts.map((template, i) => {
            return (<div key={'layout-menu-' + i} className="flex flex-col justify-center p-1 cursor-pointer"
                         onClick={() => {
                             let newSet = {}
                             newSet[lives.length] = i
                             setLayout(Object.assign({}, layout, newSet))
                         }}>
                <div className={template.grid + ' grid gap-0.5 p-0.5 w-10 h-7 bg-white rounded'}>
                    {template.items && [...Array(lives.length).keys()].map((item, j) => {
                        const itemClass = template.items[j] || ''
                        return (<div key={'layout-menu-' + i + '-' + j} className={itemClass + ' bg-slate-800'}/>)
                    })}
                </div>
            </div>)
        })}
    </div>)
}

export default LayoutMenu
