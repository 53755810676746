const CountMenu = ({online, lives, setLives}) => {
    return (<div className="flex items-center text-white px-1 text-center text-xs my-2">
        {online > 0 && (<div>
            <div className="p-1 rounded mx-1 bg-red-500">
                <span className="w-2 h-2 inline-block rounded-full bg-white mr-1"/>
                <span>LIVE</span>
            </div>
            <div className="flex mx-2 px-1 rounded-b bg-red-900">
                <div className="px-0.5">{online}</div>
                <div className="px-0.5 w-3">
                    {lives && lives.length > 0 && (<div className="cursor-pointer" onClick={() => {
                        setLives([])
                    }}>×</div>)}
                </div>
            </div>
        </div>)}
        {online <= 0 && (<div className="p-1 rounded mx-1 bg-slate-600 m-2">OFFLINE</div>)}
    </div>)
}

export default CountMenu
