import StreamsGroup from "./StreamsGroup";

const StreamsMenu = ({data, lives, setLives, chat, setChat, setCardData, setCardVisibility, setCardPosition}) => {
    return (<div
        className="grow flex items-center text-white text-center text-xs overflow-y-visible overflow-x-scroll hide-scrollbar">
        {data && data.lives && Object.keys(data.lives).map((key) => {
            return (<StreamsGroup key={'stream-group-' + key} organisations={data.organisations} organisation={key}
                                  streams={data.lives[key]} lives={lives} setLives={setLives} chat={chat}
                                  setChat={setChat} setCardData={setCardData} setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>)
        })}
    </div>)
}

export default StreamsMenu
