import Chat from "./Chat";
import Lives from "./Lives";
import Logo from "./Logo";

const Player = ({layouts, layout, lives, chat}) => {
    return (<div className={`grow flex justify-center`}>
        {lives && lives.length > 0 && (<Lives layouts={layouts} layout={layout} lives={lives}/>)}
        {chat && chat !== '' && (<Chat chat={chat}/>)}
        {(!lives || lives.length <= 0) && (
            <Logo/>)}
    </div>)
}

export default Player
