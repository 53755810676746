import StreamCard from "./StreamCard";

const Stream = ({
                    organisations,
                    stream,
                    lives,
                    setLives,
                    chat,
                    setChat,
                    setCardData,
                    setCardVisibility,
                    setCardPosition
                }) => {
    const hasLive = lives.some((live) => live.id === stream.id)
    const defaultDisabledClass = 'bg-purple-900'
    const defaultEnabledClass = 'bg-purple-500'
    const organisation = organisations[stream.organisation] || {
        disabled_class: defaultDisabledClass, enabled_class: defaultEnabledClass, fullname: ''
    }

    // noinspection JSUnresolvedVariable
    return (<div title={stream.display_name + ' - ' + stream.title}
                 className={`relative w-14 inline-block rounded-md m-1 cursor-pointer p-0.5 ${hasLive ? (organisation.enabled_class || defaultEnabledClass) : (organisation.disabled_class || defaultDisabledClass)}`}
                 onClick={() => {
                     setLives([stream])
                 }}
                 onMouseEnter={(event) => {
                     const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
                     const rt = event.target.getBoundingClientRect()
                     const ps = (rt.left + 320 + 50 > vw) ? (rt.right - 320) : rt.left
                     setCardPosition(ps)
                     setCardData({organisations: organisations, stream: stream});
                     setCardVisibility(true);
                 }}
                 onMouseLeave={(event) => {
                     setCardVisibility(false);
                 }}
    >
        <div className="flex">
            <div style={{backgroundImage: `url(${stream.profile_image_url})`}}
                 className="w-full h-10 bg-no-repeat bg-cover bg-center bg-origin-border bg-slate-800 rounded-br-md rounded-tl-md"/>
            <div className="flex flex-col">
                <div className={`grow align-middle px-0.5`} onClick={(event) => {
                    if (hasLive) {
                        setLives(lives.filter((live) => live.id !== stream.id))
                    }
                    if (!hasLive) {
                        setLives(lives.concat([stream]))
                    }
                    event.stopPropagation()
                }}>+
                </div>
                <div className={`grow align-middle px-0.5`} onClick={(event) => {
                    // noinspection JSUnresolvedVariable
                    setChat(chat === stream.login ? '' : stream.login)
                    event.stopPropagation()
                }}>...
                </div>
            </div>
        </div>
        <div className={`overflow-hidden text-ellipsis w-full`}>
            {stream.display_name}
        </div>
    </div>)
}

export default Stream
