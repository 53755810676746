import Stream from "./Stream";

const StreamsGroup = ({
                          organisations,
                          organisation,
                          streams,
                          lives,
                          setLives,
                          chat,
                          setChat,
                          setCardData,
                          setCardVisibility,
                          setCardPosition
                      }) => {
    const data = organisations[organisation] || {name: '', fullname: '', group_class: ''}
    return (<div className={`flex ${data.group_class || ''}`} title={data.fullname}>
        {data && data.name && data.name !== '' && (
            <div className="self-center w-5 text-center -rotate-90 origin-bottom-right">{data.name}</div>)}
        {streams && streams.map((stream) => {
            return (<Stream key={'stream-' + stream.id} organisations={organisations} stream={stream} lives={lives}
                            setLives={setLives} chat={chat} setChat={setChat} setCardData={setCardData}
                            setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>)
        })}
    </div>)
}

export default StreamsGroup
