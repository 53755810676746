import Menu from './components/Menu'
import {useState} from 'react';
import Player from "./components/Player";
import StreamCard from "./components/StreamCard";

function App() {
    const [lives, setLives] = useState([])
    const [layout, setLayout] = useState({})
    const [chat, setChat] = useState('')
    const [cardPosition, setCardPosition] = useState(0)
    const [cardData, setCardData] = useState({})
    const [cardVisibility, setCardVisibility] = useState(false)

    const layouts = {
        1: [{grid: '', items: []}],
        2: [{grid: 'grid-cols-2', items: []}, {grid: 'grid-rows-2', items: []}],
        3: [{
            grid: 'grid-cols-2 grid-rows-2', items: ['col-span-2', '', '']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['', 'row-span-2', '']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['', '', 'col-span-2']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['row-span-2', '', '']
        }],
        4: [{
            grid: 'grid-cols-2 grid-rows-2', items: []
        }, {
            grid: 'grid-cols-3 grid-rows-5', items: ['col-span-3 row-span-3', 'row-span-2', 'row-span-2', 'row-span-2']
        }],
        5: [{
            grid: 'grid-cols-6 grid-rows-5',
            items: ['col-span-3 row-span-3', 'col-span-3 row-span-3', 'col-span-2 row-span-2', 'col-span-2 row-span-2', 'col-span-2 row-span-2']
        }, {
            grid: 'grid-cols-4 grid-rows-3', items: ['col-span-4 row-span-2', '', '', '', '']
        }],
        6: [{
            grid: 'grid-cols-3 grid-rows-2', items: []
        }, {
            grid: 'grid-cols-4 grid-rows-3', items: ['col-span-2 row-span-2', 'col-span-2 row-span-2', '', '', '', '']
        }],
        7: [{
            grid: 'grid-cols-4 grid-rows-3',
            items: ['col-span-2 row-span-2', 'col-span-2', 'col-span-2', '', '', '', '']
        }],
        8: [{
            grid: 'grid-cols-6 grid-rows-4',
            items: ['col-span-4 row-span-2', '', '', '', '', 'col-span-2 row-span-2', 'col-span-2 row-span-2', 'col-span-2 row-span-2']
        }, {
            grid: 'grid-cols-6 grid-rows-6',
            items: ['col-span-3 row-span-4', 'col-span-3 row-span-4', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2']
        }, {
            grid: 'grid-cols-15 grid-rows-2',
            items: ['col-span-5', 'col-span-5', 'col-span-5', 'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3']
        }, {grid: 'grid-cols-4 grid-rows-2', items: []}],
        9: [{grid: 'grid-cols-3 grid-rows-3', items: []}],
        10: [{grid: 'grid-cols-5 grid-rows-2', items: []}],
        11: [{grid: 'grid-cols-4 grid-rows-3', items: []}],
        12: [{grid: 'grid-cols-4 grid-rows-3', items: []}],
        13: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        14: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        15: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        16: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        17: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        18: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        19: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        20: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        21: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        22: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        23: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        24: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        25: [{grid: 'grid-cols-5 grid-rows-5', items: []}],
        26: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        27: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        28: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        29: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        30: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
    }

    return (<div className="fixed inset-0 flex flex-col bg-gradient-to-t from-slate-900 to-violet-900">
        <StreamCard cardPosition={cardPosition} cardData={cardData} cardVisibility={cardVisibility}/>
        <Player layouts={layouts} layout={layout} lives={lives} chat={chat}/>
        <Menu lives={lives} setLives={setLives} chat={chat} setChat={setChat} layouts={layouts} layout={layout}
              setLayout={setLayout} setCardData={setCardData} setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>
        <div className="hidden" title="avoiding css purge">
            <span className="bg-gray-400"/>
            <span className="bg-gray-600"/>
            <span className="bg-gray-800"/>
            <span className="bg-blue-400"/>
            <span className="bg-blue-600"/>
            <span className="bg-blue-800"/>
            <span className="bg-yellow-200"/>
            <span className="bg-yellow-400"/>
            <span className="bg-yellow-600"/>
            <span className="bg-amber-500"/>
            <span className="bg-amber-700"/>
            <span className="bg-amber-900"/>
            <span className="bg-red-500"/>
            <span className="bg-red-700"/>
            <span className="bg-red-900"/>
            <span className="bg-rose-300"/>
            <span className="bg-rose-500"/>
            <span className="bg-rose-700"/>
            <span className="bg-sky-300"/>
            <span className="bg-sky-500"/>
            <span className="bg-sky-700"/>
            <span className="bg-teal-500"/>
            <span className="bg-teal-700"/>
            <span className="bg-teal-900"/>
            <span className="bg-green-500"/>
            <span className="bg-green-700"/>
            <span className="bg-green-900"/>
        </div>
    </div>);
}

export default App;
