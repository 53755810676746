const Lives = ({lives, layouts, layout}) => {
    let lives_index = lives.length || 1;

    if (layouts[lives_index] === undefined) {
        lives_index = 1
    }
    const layout_index = layout[lives_index] || 0;

    return (<div className={`grow grid ${layouts[lives_index][layout_index].grid || ''}`}>
        {lives && lives.length > 0 && lives.map((live, index) => {
            // noinspection JSUnresolvedVariable
            const url = `https://player.twitch.tv/?channel=${live.display_name}&parent=ffs.ellezelin.com&muted=true&autoplay=true`
            return (<div key={'live-' + live.id}
                         className={`bg-black ${layouts[lives_index][layout_index].items[index] || ''}`}>
                <iframe title="Twitch" src={url} className="w-full h-full"/>
            </div>)
        })}
    </div>)
}

export default Lives
